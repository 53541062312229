import React from 'react';
import { connect } from 'react-redux'
import config from '../config/config';
import { Grid, Card } from '@material-ui/core';
import PieChart from 'react-minimal-pie-chart';
import OnTrackPicture from '../img/on-track.jpg';
import OffTrackPicture from '../img/off-track.png';
import '../style/Charts.css';

class Charts extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const pieChartComponentWidthHeight = '60px';

    const pieChartStyle = {
      height: pieChartComponentWidthHeight,
      width: pieChartComponentWidthHeight,
      margin: 'auto',
      marginTop: '12px'
    }

    const pieChartRadius = 50;

    const red = '#e80c0c';
    const green = '#2de80c';

    const spaceBetweenCards = '10px';

    const chartImageStyle = {
      "width": "60px", 
      "height": "60px", 
      "paddingTop": "8px"
    };

    const totalBudget = (
      this.props.eatingoutBudget + this.props.entertainmentBudget + this.props.groceriesBudget + 
      this.props.miscBudget
    );

    const totalSpentThisMonth = (
      this.props.eatingoutSpentThisMonth + this.props.entertainmentSpentThisMonth + this.props.groceriesSpentThisMonth + 
      this.props.miscSpentThisMonth
    );

    // TODO refactor: it's insane to not have each card be it's own React component
    return (
      <div class="Charts-container">

              {localStorage.getItem(config.authTokenKey) == null ? <strong>Can't fetch data- not authenticated.</strong> : null}

              <Card style={{padding: '4px'}}>
                <Grid container>
                  <Grid item xs={4}>
                    <strong>Eating Out</strong>
                    <div style={{color: red, fontSize: '12px'}}>Spent: {this.props.eatingoutSpentThisMonth}</div>
                    <div style={{color: green, fontSize: '12px'}}>Remaining: {this.props.eatingoutBudget - this.props.eatingoutSpentThisMonth}</div>
                    <div style={{fontSize: '12px'}}>Budget: {this.props.eatingoutBudget}</div>
                  </Grid>

                  <Grid item xs={6}>
                    <PieChart radius={pieChartRadius} style={pieChartStyle} data={[
                      {title: 'Spent', value: this.props.eatingoutSpentThisMonth, color: red},
                      {
                        title: 'Remaining', 
                        value: Math.max((this.props.eatingoutBudget - this.props.eatingoutSpentThisMonth), 0), 
                        color: green
                      }
                    ]}/>
                  </Grid>

                  <Grid item xs={2}>
                    {
                      isCategoryOnTrack(this.props.eatingoutBudget, this.props.eatingoutSpentThisMonth) ? 
                      <img className="chart-item-photo" src={OnTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} /> :
                      <img className="chart-item-photo" src={OffTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} />
                    }
                  </Grid>
                  
                </Grid>
              </Card>

              <Card style={{padding: '4px', marginTop: spaceBetweenCards}}>
                <Grid container>
                  <Grid item xs={4}>
                    <strong>Entertainment</strong>
                    <div style={{color: red, fontSize: '12px'}}>Spent: {this.props.entertainmentSpentThisMonth}</div>
                    <div style={{color: green, fontSize: '12px'}}>Remaining: {this.props.entertainmentBudget - this.props.entertainmentSpentThisMonth}</div>
                    <div style={{fontSize: '12px'}}>Budget: {this.props.entertainmentBudget}</div>
                  </Grid>

                  <Grid item xs={6}>
                    <PieChart radius={pieChartRadius} style={pieChartStyle} data={[
                      {title: 'Spent', value: this.props.entertainmentSpentThisMonth, color: red},
                      {
                        title: 'Remaining', 
                        value: Math.max((this.props.entertainmentBudget - this.props.entertainmentSpentThisMonth), 0), 
                        color: green
                      }
                    ]}/>
                  </Grid>

                  <Grid item xs={2}>
                    {
                      isCategoryOnTrack(this.props.entertainmentBudget, this.props.entertainmentSpentThisMonth) ? 
                      <img className="chart-item-photo" src={OnTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} /> :
                      <img className="chart-item-photo" src={OffTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} />
                    }
                  </Grid>
                  
                </Grid>
              </Card>

              <Card style={{padding: '4px', marginTop: spaceBetweenCards}}>
                <Grid container>
                  <Grid item xs={4}>
                    <strong>Groceries</strong>
                    <div style={{color: red, fontSize: '12px'}}>Spent: {this.props.groceriesSpentThisMonth}</div>
                    <div style={{color: green, fontSize: '12px'}}>Remaining: {this.props.groceriesBudget - this.props.groceriesSpentThisMonth}</div>
                    <div style={{fontSize: '12px'}}>Budget: {this.props.groceriesBudget}</div>
                  </Grid>

                  <Grid item xs={6}>
                    <PieChart radius={pieChartRadius} style={pieChartStyle} data={[
                      {title: 'Spent', value: this.props.groceriesSpentThisMonth, color: red},
                      {
                        title: 'Remaining', 
                        value: Math.max((this.props.groceriesBudget - this.props.groceriesSpentThisMonth), 0), 
                        color: green
                      }
                    ]}/>
                  </Grid>

                  <Grid item xs={2}>
                    {
                      isCategoryOnTrack(this.props.groceriesBudget, this.props.groceriesSpentThisMonth) ? 
                      <img className="chart-item-photo" src={OnTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} /> :
                      <img className="chart-item-photo" src={OffTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} />
                    }
                  </Grid>
                  
                </Grid>
              </Card>

              <Card style={{padding: '4px', marginTop: spaceBetweenCards}}>
                <Grid container>
                  <Grid item xs={4}>
                    <strong>Misc.</strong>
                    <div style={{color: red, fontSize: '12px'}}>Spent: {this.props.miscSpentThisMonth}</div>
                    <div style={{color: green, fontSize: '12px'}}>Remaining: {this.props.miscBudget - this.props.miscSpentThisMonth}</div>
                    <div style={{fontSize: '12px'}}>Budget: {this.props.miscBudget}</div>
                  </Grid>

                  <Grid item xs={6}>
                    <PieChart radius={pieChartRadius} style={pieChartStyle} data={[
                      {title: 'Spent', value: this.props.miscSpentThisMonth, color: red},
                      {
                        title: 'Remaining', 
                        value: Math.max((this.props.miscBudget - this.props.miscSpentThisMonth), 0), 
                        color: green
                      }
                    ]}/>
                  </Grid>

                  <Grid item xs={2}>
                    {
                      isCategoryOnTrack(this.props.miscBudget, this.props.miscSpentThisMonth) ? 
                      <img className="chart-item-photo" src={OnTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} /> :
                      <img className="chart-item-photo" src={OffTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} />
                    }
                  </Grid>
                  
                </Grid>
              </Card>

              <Card style={{padding: '4px', marginTop: spaceBetweenCards}}>
                <Grid container>
                  <Grid item xs={4}>
                    <strong>Fun Money</strong>
                    <div style={{color: red, fontSize: '12px'}}>Spent: {this.props.funmoneySpentThisMonth}</div>
                    <div style={{color: green, fontSize: '12px'}}>Remaining: {this.props.funmoneyBudget - this.props.funmoneySpentThisMonth}</div>
                    <div style={{fontSize: '12px'}}>Budget: {this.props.funmoneyBudget}</div>
                  </Grid>

                  <Grid item xs={6}>
                    <PieChart radius={pieChartRadius} style={pieChartStyle} data={[
                      {title: 'Spent', value: this.props.funmoneySpentThisMonth, color: red},
                      {
                        title: 'Remaining', 
                        value: Math.max((this.props.funmoneyBudget - this.props.funmoneySpentThisMonth), 0), 
                        color: green
                      }
                    ]}/>
                  </Grid>

                  <Grid item xs={2}>
                    {
                      isCategoryOnTrack(this.props.funmoneyBudget, this.props.funmoneySpentThisMonth) ? 
                      <img className="chart-item-photo" src={OnTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} /> :
                      <img className="chart-item-photo" src={OffTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} />
                    }
                  </Grid>
                  
                </Grid>
              </Card>

              <Card style={{padding: '4px', marginTop: "40px"}}>
                <Grid container>
                  <Grid item xs={4}>
                    <strong>Total</strong>
                    <div style={{color: red, fontSize: '12px'}}>Spent: {totalSpentThisMonth}</div>
                    <div style={{color: green, fontSize: '12px'}}>Remaining: {totalBudget - totalSpentThisMonth}</div>
                    <div style={{fontSize: '12px'}}>Budget: {totalBudget}</div>
                  </Grid>

                  <Grid item xs={6}>
                    <PieChart radius={pieChartRadius} style={pieChartStyle} data={[
                      {title: 'Spent', value: totalSpentThisMonth, color: red},
                      {
                        title: 'Remaining', 
                        value: Math.max((totalBudget - totalSpentThisMonth), 0), 
                        color: green
                      }
                    ]}/>
                  </Grid>

                  <Grid item xs={2}>
                    {
                      isCategoryOnTrack(totalBudget, totalSpentThisMonth) ? 
                      <img className="chart-item-photo" src={OnTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} /> :
                      <img className="chart-item-photo" src={OffTrackPicture} alt={"Monthly Progress Indicator Icon"} style={chartImageStyle} />
                    }
                  </Grid>
                  
                </Grid>
              </Card>
        </div>
    );
  }

}

const isCategoryOnTrack = (monthlyBudget, amountSpent) => {
  var now = new Date();
  const daysInMonth = new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
  const daysPassed = now.getDate();
  const daysRatio = daysPassed / daysInMonth;
  const spentRatio = amountSpent / monthlyBudget;
  console.log(daysInMonth, daysPassed, daysRatio, spentRatio)
  return spentRatio <= daysRatio;
}

const mapStateToProps = state => {
  return {
    eatingoutBudget: state.reducer.eatingoutBudget,
    entertainmentBudget: state.reducer.entertainmentBudget,
    groceriesBudget: state.reducer.groceriesBudget,
    miscBudget: state.reducer.miscBudget,
    funmoneyBudget: state.reducer.funmoneyBudget,
    eatingoutSpentThisMonth: state.reducer.eatingoutSpentThisMonth,
    entertainmentSpentThisMonth: state.reducer.entertainmentSpentThisMonth,
    groceriesSpentThisMonth: state.reducer.groceriesSpentThisMonth,
    miscSpentThisMonth: state.reducer.miscSpentThisMonth,
    funmoneySpentThisMonth: state.reducer.funmoneySpentThisMonth,
    needToRedirectHomeAndRefresh: state.reducer.needToRedirectHomeAndRefresh
  }
}

export default connect(mapStateToProps)(Charts);