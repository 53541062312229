import config from '../config/config';

export const FETCH_EATINGOUT_BUDGET = "FETCH_EATINGOUT_BUDGET"
export const FETCH_ENTERTAINMENT_BUDGET = "FETCH_ENTERTAINMENT_BUDGET";
export const FETCH_GROCERIES_BUDGET = "FETCH_GROCERIES_BUDGET";
export const FETCH_BRUNO_BUDGET = "FETCH_BRUNO_BUDGET";
export const FETCH_MISC_BUDGET = "FETCH_MISC_BUDGET";
export const FETCH_HEALTHCARE_BUDGET = "FETCH_HEALTHCARE_BUDGET";
export const FETCH_FUNMONEY_BUDGET = "FETCH_FUNMONEY_BUDGET";
export const FETCH_EATINGOUT_SPENT_THIS_MONTH = "FETCH_EATINGOUT_SPENT_THIS_MONTH";
export const FETCH_ENTERTAINMENT_SPENT_THIS_MONTH = "FETCH_ENTERTAINMENT_SPENT_THIS_MONTH";
export const FETCH_GROCERIES_SPENT_THIS_MONTH = "FETCH_GROCERIES_SPENT_THIS_MONTH";
export const FETCH_BRUNO_SPENT_THIS_MONTH = "FETCH_BRUNO_SPENT_THIS_MONTH";
export const FETCH_MISC_SPENT_THIS_MONTH = "FETCH_MISC_SPENT_THIS_MONTH";
export const FETCH_HEALTHCARE_SPENT_THIS_MONTH = "FETCH_HEALTHCARE_SPENT_THIS_MONTH";
export const FETCH_FUNMONEY_SPENT_THIS_MONTH = "FETCH_FUNMONEY_SPENT_THIS_MONTH";
export const FETCH_TXNS = "FETCH_TXNS";
export const FETCH_FUN_MONEY_X_MONTHS = "FETCH_FUN_MONEY_X_MONTHS";
export const POST_LOGIN = "POST_LOGIN";
export const POST_EATINGOUT_TXN_SUCCESS = "POST_EATINGOUT_TXN_SUCCESS";
export const POST_ENTERTAINMENT_TXN_SUCCESS = "POST_ENTERTAINMENT_TXN_SUCCESS";
export const POST_GROCERIES_TXN_SUCCESS = "POST_GROCERIES_TXN_SUCCESS";
export const POST_BRUNO_TXN_SUCCESS = "POST_BRUNO_TXN_SUCCESS";
export const POST_MISC_TXN_SUCCESS = "POST_MISC_TXN_SUCCESS";
export const POST_HEALTHCARE_TXN_SUCCESS = "POST_HEALTHCARE_TXN_SUCCESS";
export const POST_FUNMONEY_TXN_SUCCESS = "POST_FUNMONEY_TXN_SUCCESS";
export const DATA_REFRESHED = "DATA_REFRESHED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const fetchEatingoutBudget = () => {
  return dispatch => {
    const url = getApiUrl('/eatingout/budget');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_EATINGOUT_BUDGET,
          budget: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchEntertainmentBudget = () => {
  return dispatch => {
    const url = getApiUrl('/entertainment/budget');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_ENTERTAINMENT_BUDGET,
          budget: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchGroceriesBudget = () => {
  return dispatch => {
    const url = getApiUrl('/groceries/budget');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_GROCERIES_BUDGET,
          budget: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchBrunoBudget = () => {
  return dispatch => {
    const url = getApiUrl('/bruno/budget');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_BRUNO_BUDGET,
          budget: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchMiscBudget = () => {
  return dispatch => {
    const url = getApiUrl('/misc/budget');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_MISC_BUDGET,
          budget: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchHealthcareBudget = () => {
  return dispatch => {
    const url = getApiUrl('/healthcare/budget');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_HEALTHCARE_BUDGET,
          budget: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchFunmoneyBudget = () => {
  return dispatch => {
    const url = getApiUrl('/funmoney/budget');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_FUNMONEY_BUDGET,
          budget: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}
    
export const fetchEatingoutSpentThisMonth = () => {
  return dispatch => {
    const url = getApiUrl('/eatingout/spentThisMonth');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_EATINGOUT_SPENT_THIS_MONTH,
          spent: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchEntertainmentSpentThisMonth = () => {
  return dispatch => {
    const url = getApiUrl('/entertainment/spentThisMonth');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_ENTERTAINMENT_SPENT_THIS_MONTH,
          spent: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchGroceriesSpentThisMonth = () => {
  return dispatch => {
    const url = getApiUrl('/groceries/spentThisMonth');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_GROCERIES_SPENT_THIS_MONTH,
          spent: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchBrunoSpentThisMonth = () => {
  return dispatch => {
    const url = getApiUrl('/bruno/spentThisMonth');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_BRUNO_SPENT_THIS_MONTH,
          spent: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchMiscSpentThisMonth = () => {
  return dispatch => {
    const url = getApiUrl('/misc/spentThisMonth');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_MISC_SPENT_THIS_MONTH,
          spent: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchHealthcareSpentThisMonth = () => {
  return dispatch => {
    const url = getApiUrl('/healthcare/spentThisMonth');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_HEALTHCARE_SPENT_THIS_MONTH,
          spent: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchFunmoneySpentThisMonth = () => {
  return dispatch => {
    const url = getApiUrl('/funmoney/spentThisMonth');
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(text => {
        dispatch({
          type: FETCH_FUNMONEY_SPENT_THIS_MONTH,
          spent: parseInt(text)
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchTxns = (txnType, startDate, endDate) => {
  return dispatch => {
    const queryParams = new URLSearchParams({
      txnType: txnType,
      startDate: startDate,
      endDate: endDate
    });

    const url = getApiUrl(`/txns/withFilters?${queryParams.toString()}`);
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: FETCH_TXNS,
          txns: json
        })
      })
      .catch((error) => console.log(error))
  }
}

export const fetchFunMoneyXMonths = (numberOfMonths) => {
  return dispatch => {
    const url = getApiUrl(`/funmoney/spentLastXMonths?numberOfMonths=${numberOfMonths}`);
    const requestMeta = {
      method: 'GET',
      headers: getHeaders()
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.json())
      .then(json => {
        dispatch({
          type: FETCH_FUN_MONEY_X_MONTHS,
          funMoneyXMonths: json
        })
      })
      .catch((error) => console.log(error))
  }
}

export const postLogin = (username, password) => {
  return dispatch => {
    var sha256 = require('js-sha256');
    var hash = sha256.create();
    hash.update(password);
    const passhash = hexToBase64(hash.hex());

    const url = getApiUrl('/login');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { username, passhash } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => res.text())
      .then(token => {
        if (token) {
          localStorage.setItem(config.authTokenKey, token);
        }
        dispatch({
          type: POST_LOGIN,
          token: token
        })
        dispatch({
          type: LOGIN_SUCCESS
        });
      })
      .catch((error) => console.log(error))
  }
}

export const postEatingoutTransaction = (amount, description) => {
  return dispatch => {
    const url = getApiUrl('/eatingout/txn');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { amount, description } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => {
        dispatch({
          type: POST_EATINGOUT_TXN_SUCCESS
        })
      })
      .catch((error) => console.log(error))
  }
}

export const postEntertainmentTransaction = (amount, description) => {
  return dispatch => {
    const url = getApiUrl('/entertainment/txn');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { amount, description } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => {
        dispatch({
          type: POST_ENTERTAINMENT_TXN_SUCCESS
        })
      })
      .catch((error) => console.log(error))
  }
}

export const postGroceriesTransaction = (amount, description) => {
  return dispatch => {
    const url = getApiUrl('/groceries/txn');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { amount, description } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => {
        dispatch({
          type: POST_GROCERIES_TXN_SUCCESS
        })
      })
      .catch((error) => console.log(error))
  }
}

export const postBrunoTransaction = (amount, description) => {
  return dispatch => {
    const url = getApiUrl('/bruno/txn');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { amount, description } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => {
        dispatch({
          type: POST_BRUNO_TXN_SUCCESS
        })
      })
      .catch((error) => console.log(error))
  }
}

export const postMiscTransaction = (amount, description) => {
  return dispatch => {
    const url = getApiUrl('/misc/txn');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { amount, description } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => {
        dispatch({
          type: POST_MISC_TXN_SUCCESS
        })
      })
      .catch((error) => console.log(error))
  }
}

export const postHealthcareTransaction = (amount, description) => {
  return dispatch => {
    const url = getApiUrl('/healthcare/txn');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { amount, description } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => {
        dispatch({
          type: POST_HEALTHCARE_TXN_SUCCESS
        })
      })
      .catch((error) => console.log(error))
  }
}

export const postFunmoneyTransaction = (amount, description) => {
  return dispatch => {
    const url = getApiUrl('/funmoney/txn');
    const requestMeta = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify( { amount, description } )
    }

    fetch(new Request(url, requestMeta))
      .then(handleErrors)
      .then(res => {
        dispatch({
          type: POST_FUNMONEY_TXN_SUCCESS
        })
      })
      .catch((error) => console.log(error))
  }
}

export const emitDataRefreshed = () => {
  return {
    type: DATA_REFRESHED
  }
}

const getApiUrl = (route) => {
  return config.apiUrl + route;
}

const getHeaders = () => {
  return {
    'Authorization': localStorage.getItem(config.authTokenKey),
    'Content-Type': 'application/json'
  }
}

const hexToBase64 = (hexstring) => {
  return btoa(hexstring.match(/\w{2}/g).map(function(a) {
      return String.fromCharCode(parseInt(a, 16));
  }).join(""));
}

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}