import { combineReducers } from "redux";
import {
  FETCH_EATINGOUT_BUDGET,
  FETCH_EATINGOUT_SPENT_THIS_MONTH,
  FETCH_ENTERTAINMENT_BUDGET,
  FETCH_GROCERIES_BUDGET,
  FETCH_BRUNO_BUDGET,
  FETCH_MISC_BUDGET,
  FETCH_HEALTHCARE_BUDGET,
  FETCH_FUNMONEY_BUDGET,
  FETCH_ENTERTAINMENT_SPENT_THIS_MONTH,
  FETCH_GROCERIES_SPENT_THIS_MONTH,
  FETCH_BRUNO_SPENT_THIS_MONTH,
  FETCH_MISC_SPENT_THIS_MONTH,
  FETCH_HEALTHCARE_SPENT_THIS_MONTH,
  FETCH_FUNMONEY_SPENT_THIS_MONTH,
  FETCH_TXNS,
  FETCH_FUN_MONEY_X_MONTHS,
  POST_LOGIN,
  POST_EATINGOUT_TXN_SUCCESS,
  POST_ENTERTAINMENT_TXN_SUCCESS,
  POST_GROCERIES_TXN_SUCCESS,
  POST_BRUNO_TXN_SUCCESS,
  POST_MISC_TXN_SUCCESS,
  POST_HEALTHCARE_TXN_SUCCESS,
  POST_FUNMONEY_TXN_SUCCESS,
  DATA_REFRESHED,
  LOGIN_SUCCESS
} from '../actions';

const initialState = {
  loginTokenResponse: null,

  eatingoutBudget: null,
  eatingoutSpentThisMonth: null,

  entertainmentBudget: null,
  entertainmentSpentThisMonth: null,

  brunoBudget: null,
  brunoSpentThisMonth: null,

  miscBudget: null,
  miscSpentThisMonth: null,

  healthcareBudget: null,
  healthcareSpentThisMonth: null,

  funmoneyBudget: null,
  funmoneySpentThisMonth: null,

  txns: null,
  funMoneyXMonths: null,

  needToRedirectHomeAndRefresh: false
}

const reducer = (state=initialState, action) => {
  switch(action.type) {
    case FETCH_EATINGOUT_BUDGET: 
      return {
        ...state,
        eatingoutBudget: action.budget
      }
    case FETCH_ENTERTAINMENT_BUDGET:
      return {
        ...state,
        entertainmentBudget: action.budget
      }
    case FETCH_GROCERIES_BUDGET:
      return {
        ...state,
        groceriesBudget: action.budget
      }
    case FETCH_BRUNO_BUDGET:
      return {
        ...state,
        brunoBudget: action.budget
      }
    case FETCH_MISC_BUDGET:
      return {
        ...state,
        miscBudget: action.budget
      }
    case FETCH_HEALTHCARE_BUDGET:
      return {
        ...state,
        healthcareBudget: action.budget
      }
    case FETCH_FUNMONEY_BUDGET:
      return {
        ...state,
        funmoneyBudget: action.budget
      } 
    case FETCH_EATINGOUT_SPENT_THIS_MONTH:
      return {
        ...state,
        eatingoutSpentThisMonth: action.spent
      }
    case FETCH_ENTERTAINMENT_SPENT_THIS_MONTH:
      return {
        ...state,
        entertainmentSpentThisMonth: action.spent
      }
    case FETCH_GROCERIES_SPENT_THIS_MONTH:
      return {
        ...state,
        groceriesSpentThisMonth: action.spent
      }
    case FETCH_BRUNO_SPENT_THIS_MONTH:
      return {
        ...state,
        brunoSpentThisMonth: action.spent
      }
    case FETCH_MISC_SPENT_THIS_MONTH:
      return {
        ...state,
        miscSpentThisMonth: action.spent
      }
    case FETCH_HEALTHCARE_SPENT_THIS_MONTH:
      return {
        ...state,
        healthcareSpentThisMonth: action.spent
      }
    case FETCH_FUNMONEY_SPENT_THIS_MONTH:
      return {
        ...state,
        funmoneySpentThisMonth: action.spent
      } 
    case FETCH_TXNS:
      return {
        ...state,
        txns: action.txns
      }
    case FETCH_FUN_MONEY_X_MONTHS:
      return {
        ...state,
        funMoneyXMonths: action.funMoneyXMonths
      }  
    case POST_EATINGOUT_TXN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }
    case POST_ENTERTAINMENT_TXN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }
    case POST_GROCERIES_TXN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }
    case POST_BRUNO_TXN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }
    case POST_MISC_TXN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }
    case POST_HEALTHCARE_TXN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }
    case POST_FUNMONEY_TXN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }   
    case POST_LOGIN:
      return {
        ...state,
        loginTokenResponse: action.token
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        needToRedirectHomeAndRefresh: true
      }  
    case DATA_REFRESHED:
      return {
        ...state,
        needToRedirectHomeAndRefresh: false
      }  
    default:
      return state;
  }
}

const rootReducer = combineReducers({ reducer });

export default rootReducer;