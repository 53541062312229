import React from 'react';
import ReactDOM from 'react-dom';
import config from './config/config';
import './style/index.css';
import { Redirect, Route, Switch, HashRouter as Router } from 'react-router-dom';
import Login from './components/Login';
import AddTxn from './components/AddTxn';
import Charts from './components/Charts';
import ViewTxns from './components/ViewTxns';
import History from './components/History';
import Footer from './components/Footer';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './redux/store';

const routing = (
  <Router>
    <div className="index-wholeAppContainer">
      <div className="index-maxWidthContainer">
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />

        {!(localStorage.getItem(config.authTokenKey)) ? <Redirect to='/login' /> : null}

        <Provider store={store}>
          
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/viewTxns" component={ViewTxns} />
            <Route path="/charts" component={Charts} />
            <Route path="/history" component={History} />
            <Route path="/" component={AddTxn} />
          </Switch>
        
          <Footer />
            
        </Provider>

      </div>
    </div>
  </Router>
);

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
