import React from 'react'
import { Redirect } from 'react-router-dom';
import { Card } from '@material-ui/core';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import '../style/Login.css';
import config from '../config/config';
import { postLogin } from '../redux/actions'
import { connect } from 'react-redux'

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '', 
      password: ''
    }


  }

  handleUsernameChange = (e) => {
    this.setState({
      username: e.target.value
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handleSubmitClick = () => {
    this.props.dispatch( postLogin(this.state.username, this.state.password) );
  }

  render() {
    return(
      <div>

        {localStorage.getItem(config.authTokenKey) ? <Redirect to='/' /> : null}
        {this.props.loginTokenResponse ? <Redirect to='/' /> : null}

        <h1 className="Login-title">Login</h1>
        <Card raised className="Login-card-container">
          <Grid container spacing={2}>
            
            <Grid item xs={12}>
              <TextField id="login-username" variant="outlined" label="Username" 
                fullWidth onChange={this.handleUsernameChange} value={this.state.username} />
            </Grid>

            <Grid item xs={12}>
              <TextField id="login-password" variant="outlined" label="Password" 
                fullWidth onChange={this.handlePasswordChange} value={this.state.password} />
            </Grid>

            <Grid item xs={8}></Grid>

            <Grid item xs={4}>
              <Button variant="contained" color="primary" size="large" onClick={this.handleSubmitClick}>Submit</Button>
            </Grid>
            
          </Grid>
        </Card>
      </div>
    );  
  }
}

const mapStateToProps = (state) => {
  return {
    loginTokenResponse: state.reducer.loginTokenResponse
  }
}

export default connect(mapStateToProps)(Login);