import React from 'react'
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchEatingoutBudget, 
  fetchEntertainmentBudget,
  fetchGroceriesBudget,
  fetchMiscBudget,
  fetchFunmoneyBudget,
  fetchEatingoutSpentThisMonth, 
  fetchEntertainmentSpentThisMonth,
  fetchGroceriesSpentThisMonth,
  fetchMiscSpentThisMonth,
  fetchFunmoneySpentThisMonth,
  emitDataRefreshed
 } from '../redux/actions';
import '../style/Footer.css';

class Footer extends React.Component {

  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth, height: window.innerHeight,  redirectHome: false};

    this.fetchBudgetsAndSpentAmounts();
  }

  fetchBudgetsAndSpentAmounts() {
    this.props.dispatch( fetchEatingoutBudget() );
    this.props.dispatch( fetchEntertainmentBudget() );
    this.props.dispatch( fetchGroceriesBudget() );
    this.props.dispatch( fetchMiscBudget() );
    this.props.dispatch( fetchFunmoneyBudget() );
    this.props.dispatch( fetchEatingoutSpentThisMonth() );
    this.props.dispatch( fetchEntertainmentSpentThisMonth() );
    this.props.dispatch( fetchGroceriesSpentThisMonth() );
    this.props.dispatch( fetchMiscSpentThisMonth() );
    this.props.dispatch( fetchFunmoneySpentThisMonth() );
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleRefreshDataIfNeeded = () => {
    if (this.props.needToRedirectHomeAndRefresh) {
      this.fetchBudgetsAndSpentAmounts();
      this.props.dispatch( emitDataRefreshed() );
    }
  }

  handleToggleChartsClick = () => {
    var hidableContainer = document.getElementById("hidable-container");
    
    if (!hidableContainer.style.display) {
      // Go ahead, try removing this seemingly redundant check.
      // This avoids some BULLSHIT.
      hidableContainer.style.display = "none";
    }

    var chartsDisplaying = hidableContainer.style.display !== "none";
    var nextVal = chartsDisplaying ? "none" : "block";
    hidableContainer.style.display = nextVal;
  }

  handleHomeClick = () => {
    this.setState({
      redirectHome: true
    })
  }

  render() {
    this.handleRefreshDataIfNeeded();

    if (this.props.needToRedirectHomeAndRefresh || this.state.redirectHome) {
      window.location = '/';
    }

    return (
      <div className="Footer-container">
        <Button variant="contained" color="primary" size="small" onClick={this.handleHomeClick}>Add</Button> &nbsp;
        <Link to='/charts'>
          <Button variant="contained" color="primary" size="small">Charts</Button>
        </Link> &nbsp;
        <Link to='/viewTxns'>
          <Button variant="contained" color="primary" size="small">Txns</Button>
        </Link> &nbsp;
        <Link to='/history'>
          <Button variant="contained" color="primary" size="small">Hstry</Button>
        </Link>

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    eatingoutBudget: state.reducer.eatingoutBudget,
    entertainmentBudget: state.reducer.entertainmentBudget,
    groceriesBudget: state.reducer.groceriesBudget,
    miscBudget: state.reducer.miscBudget,
    funmoneyBudget: state.reducer.funmoneyBudget,
    eatingoutSpentThisMonth: state.reducer.eatingoutSpentThisMonth,
    entertainmentSpentThisMonth: state.reducer.entertainmentSpentThisMonth,
    groceriesSpentThisMonth: state.reducer.groceriesSpentThisMonth,
    miscSpentThisMonth: state.reducer.miscSpentThisMonth,
    funmoneySpentThisMonth: state.reducer.funmoneySpentThisMonth,
    needToRedirectHomeAndRefresh: state.reducer.needToRedirectHomeAndRefresh
  }
}

export default connect(mapStateToProps)(Footer);