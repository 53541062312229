import React from 'react'
import { Button, 
  Grid, 
  Card, 
  TextField, 
  Select,
  MenuItem 
} from '@material-ui/core';
import { connect } from 'react-redux'
import { postGroceriesTransaction, 
  postEatingoutTransaction, 
  postEntertainmentTransaction,  
  postMiscTransaction,
  postFunmoneyTransaction
} from '../redux/actions'
import '../style/AddTxn.css';

class AddTxn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 'eatingOut',
      amount: '',
      description: ''
    }
  }

  handleCategorySelectChange = (e) => {
    console.log(e.target.value);
    this.setState({
      category: e.target.value
    })
  }

  handleAmountChange = (e) => {
    this.setState({
      amount: e.target.value
    });
  }

  handleDescriptionChange = (e) => {
    this.setState({
      description: e.target.value
    });
  }

  handleSubmitClick = () => {
    const category = this.state.category;

    if (category === 'eatingOut') {
      this.props.dispatch( postEatingoutTransaction(this.state.amount, this.state.description) );
    } else if (category === 'entertainment') {
      this.props.dispatch( postEntertainmentTransaction(this.state.amount, this.state.description) );
    } else if (category === 'groceries') {
      this.props.dispatch( postGroceriesTransaction(this.state.amount, this.state.description) );
    } else if (category === 'misc') {
      this.props.dispatch( postMiscTransaction(this.state.amount, this.state.description) );
    } else if (category === 'funmoney') {
      this.props.dispatch( postFunmoneyTransaction(this.state.amount, this.state.description) );
    }
  }

  render() {

    return (
      <div style={{width: '95%', margin: 'auto'}}>
        <h3>Add a Transaction</h3>
        <Card raised className="AddTxn-card-container">
          <Grid container spacing={2}>

            <Grid item xs={12}>
              <div style={{height: '5px'}}/>
              <strong>Category</strong>&nbsp;&nbsp;&nbsp;&nbsp;
              <Select
                labelId="txn-category-select-label"
                label="Category"
                id="txn-category-select"
                value={this.state.category}
                onChange={this.handleCategorySelectChange}
                style={{width: '200px', height: '50px'}}
              >
                <MenuItem value={'eatingOut'}>Eating Out</MenuItem>
                <MenuItem value={'entertainment'}>Entertainment</MenuItem>
                <MenuItem value={'groceries'}>Groceries</MenuItem>
                <MenuItem value={'misc'}>Misc</MenuItem>
                <MenuItem value={'funmoney'}>Fun Money</MenuItem>
              </Select>
            </Grid>
            
            <Grid item xs={12}>
              <TextField id="addTxn-amount" variant="outlined" label="Amount" 
                fullWidth type='number' onChange={this.handleAmountChange} value={this.state.amount} />
            </Grid>

            <Grid item xs={12}>
              <TextField id="addTxn-description" variant="outlined" label="Description" 
                fullWidth onChange={this.handleDescriptionChange} value={this.state.description} />
            </Grid>

            <Grid item xs={8}></Grid>

            <Grid item xs={4}>
              <Button variant="contained" color="primary" size="large" onClick={this.handleSubmitClick}>Submit</Button>
            </Grid>
            
          </Grid>
        </Card>
        
      </div>
    )
  }
}

export default connect()(AddTxn);