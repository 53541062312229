import React from 'react';
import { connect } from 'react-redux'
import config from '../config/config';
import { Grid, Card, MenuItem, Select } from '@material-ui/core';
import { fetchFunMoneyXMonths } from '../redux/actions';
import PieChart from 'react-minimal-pie-chart';
import '../style/Charts.css';

class Charts extends React.Component {

  state = {
    funMoneyNumberOfMonths: 3
  }

  constructor(props) {
    super(props);
    this.props.dispatch( fetchFunMoneyXMonths(this.state.funMoneyNumberOfMonths) );
  }

  handleFunMoneyNumMonthsChange = (event) => {
    this.setState({
        funMoneyNumberOfMonths: event.target.value
    })
    this.props.dispatch( fetchFunMoneyXMonths(event.target.value) );
}

  render() {
    const pieChartComponentWidthHeight = '60px';

    const pieChartStyle = {
      height: pieChartComponentWidthHeight,
      width: pieChartComponentWidthHeight,
      margin: 'auto',
      marginTop: '12px'
    }

    const pieChartRadius = 50;

    const red = '#e80c0c';
    const green = '#2de80c';

    const spaceBetweenCards = '10px';

    var funMoneyXMonthsSpent = this.props.funMoneyXMonths ? this.props.funMoneyXMonths.spent : undefined;
    var funMoneyXMonthsBudget = this.props.funMoneyXMonths ? this.props.funMoneyXMonths.budget : undefined;

    return (
      <div class="Charts-container">

              {localStorage.getItem(config.authTokenKey) == null ? <strong>Can't fetch data- not authenticated.</strong> : null}

              <Card style={{padding: '10px'}}>
                <Grid container>
                  <Grid item xs={8}>
                    <strong>Fun money over time</strong>
                    <div style={{color: red, fontSize: '12px'}}>Spent: {funMoneyXMonthsSpent}</div>
                    <div style={{fontSize: '12px'}}>Budget: {funMoneyXMonthsBudget}</div>
                  </Grid>

                  <Grid item xs={4}>
                    <Select
                        value={this.state.funMoneyNumberOfMonths}
                        onChange={this.handleFunMoneyNumMonthsChange}
                        displayEmpty
                        >
                        <MenuItem value={2}>2 months</MenuItem>
                        <MenuItem value={3}>3 months</MenuItem>
                        <MenuItem value={4}>4 months</MenuItem>
                        <MenuItem value={5}>5 months</MenuItem>
                        <MenuItem value={6}>6 months</MenuItem>
                        <MenuItem value={7}>7 months</MenuItem>
                        <MenuItem value={8}>8 months</MenuItem>
                        <MenuItem value={9}>9 months</MenuItem>
                        <MenuItem value={10}>10 months</MenuItem>
                        <MenuItem value={11}>11 months</MenuItem>
                        <MenuItem value={12}>12 months</MenuItem>
                    </Select>
                  </Grid>
                  
                </Grid>
              </Card>
        </div>
    );
  }

}

const mapStateToProps = state => {
  return {
    funMoneyXMonths: state.reducer.funMoneyXMonths,
    needToRedirectHomeAndRefresh: state.reducer.needToRedirectHomeAndRefresh
  }
}

export default connect(mapStateToProps)(Charts);