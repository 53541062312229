import React from 'react';
import { 
  Button, 
  Card, 
  FormControl, 
  Grid, 
  InputLabel, 
  Select, 
  MenuItem, 
  FormHelperText 
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux'
import { fetchTxns } from '../redux/actions';
import '../style/ViewTxns.css';

class ViewTxns extends React.Component {

  state = {
    txnType: "",
    startDate: sixMonthsAgo(),
    endDate: today()
  }

  constructor(props) {
    super(props);
    this.buildAndSendApiRequest();
  }

  buildAndSendApiRequest() {
    // Format the dates as "YYYY-MM-DD"
    // dispatch the action that sends the API request
    const startYear = this.state.startDate.getFullYear();
    const startMonth = String(this.state.startDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const startDay = String(this.state.startDate.getDate()).padStart(2, '0');
    const startDateParam = `${startYear}-${startMonth}-${startDay}`;

    const endYear = this.state.endDate.getFullYear();
    const endMonth = String(this.state.endDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month
    const endDay = String(this.state.endDate.getDate()).padStart(2, '0');
    const endDateParam = `${endYear}-${endMonth}-${endDay}`;

    this.props.dispatch( fetchTxns(this.state.txnType, startDateParam, endDateParam) );
  }

  render() {
    return (
      <div style={{margin: "10px"}}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl>
              <InputLabel shrink id="type-input-label">
                Type
              </InputLabel>
              <Select
                labelId="type-input-label"
                id="type-input-select"
                value={this.state.txnType}
                onChange={this.handleTypeFilterChange}
              >
                <MenuItem value={""}>No Filter</MenuItem>
                <MenuItem value={"funmoney"}>Fun Money</MenuItem>
                <MenuItem value={"groceries"}>Groceries</MenuItem>
                <MenuItem value={"eatingOut"}>Eating Out</MenuItem>
                <MenuItem value={"entertainment"}>Entertainment</MenuItem>
                <MenuItem value={"misc"}>Miscellaneous</MenuItem>
              </Select>
              <FormHelperText>Transaction Type</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="startDate-picker"
                label="Start Date"
                value={this.state.startDate}
                onChange={this.handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="endDate-picker"
                label="End Date"
                value={this.state.endDate}
                onChange={this.handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Button variant="contained" color="primary" size="small" 
          style={{margin: '10px'}} onClick={this.handleSubmitClick}>Submit</Button>

        <Card raised className="ViewTxns-card-container" elevation={18} >
          <div className="ViewTxns-table-container">
            { this.props.txns ? 
                this.txnListToTable(this.props.txns) : 
                null }
          </div>
        </Card>

      </div>
    );
  }

  txnListToTable = (txnList) => {
    return (
      <table>
        <thead>
          <tr style={{background: '#e3e7ea'}}>
            <th>User</th>
            <th>Category</th>
            <th>Amount</th>
            <th>Description</th>
            <th>Timestamp</th>
          </tr>
        </thead>

        <tbody>
          {txnList.map(function(txn) {
            return (
              <tr style={getRowStyle(txn)}>
                <td>{transformUsername(txn.username)}</td>
                <td>{txn.name}</td>
                <td>{txn.amount}</td>
                <td>{txn.description}</td>
                <td>{transformTimestamp(txn.added_at)}</td>
              </tr>
            )
          })}
        </tbody>

      </table>
    )
  }

  handleSubmitClick = () => {
    this.buildAndSendApiRequest();
  }

  handleTypeFilterChange = (event) => {
    this.setState({
      txnType: event.target.value
    });
  }

  handleStartDateChange = (date) => {
    this.setState({
      startDate: date
    });
  }

  handleEndDateChange = (date) => {
    this.setState({
      endDate: date
    });
  } 

}

const mapStateToProps = (state) => {
  return {
    txns: state.reducer.txns
  }
}

const transformUsername = (username) => {
  if (username === 'alextotheroh') {
    return 'Alex';
  }

  return username;
}

const transformTimestamp = (timestamp) => {
    var date = timestamp.split('T')[0]
    var time = timestamp.split('T')[1]
    time = time.substring(0, time.length - 8)
    return date + ' ' + time
}

const getRowStyle = (txn) => {
  if (txn.username === 'alextotheroh') {
    return {background: '#7db8e7'}
  }

  return {background: '#bfd7ea'}
}

const today = () => {
  return new Date();
}

const sixMonthsAgo = () => {
  const today = new Date();
  const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, 1);
  return sixMonthsAgo;
}

export default connect(mapStateToProps)(ViewTxns);